export default class Feriado {
  constructor (id = '') {
    this.id = id
    this.data = ''
    this.descricao = ''
    this.ano = ''
    this.tpFeriado = ''
    this.tpAmbito = ''
    this.uf = ''
    this.cdMunicipio = ''
    this.tpAtividade = ''
  }

  static validar (objeto, validador, erros) {
    let erro = false

    if (!objeto.data) {
      erros.data = 'Deve ser informado'
      erro = true
    }

    if (!objeto.descricao) {
      erros.descricao = 'Deve ser informado'
      erro = true
    }

    if (!objeto.tpFeriado) {
      erros.tpFeriado = 'Deve ser informado'
      erro = true
    }

    if (!objeto.tpAmbito) {
      erros.tpAmbito = 'Deve ser informado'
      erro = true
    }

    if ((objeto.tpAmbito === 'E' || objeto.tpAmbito === 'M') && !objeto.uf) {
      erro = true
      erros.uf = 'Deve ser informado'
    }

    if (objeto.tpAmbito === 'M' && !objeto.cdMunicipio) {
      erro = true
      erros.cdMunicipio = 'Deve ser informado'
    }

    if (validador === false) {
      erro = true
      erros.data = 'Informe uma data válida'
    }

    return erro
  }
}
