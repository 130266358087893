<template>
  <div class="is-flex is-flex-direction-column is-desktop" id="feriados">
    <div class="column is-full">
      <page-title v-if="this.showTitle" >Feriados</page-title>
      <DataTableBasico
        ref="datatable"
        customSearch
        :key="datatableKey"
        :pStatus="this.status"
        :pDataSource="this.dataSource"
        :pData="this.feriados"
        :pHideButtonMore="this.hideButtonMore"
        :pQuantidadeDeItems="this.page.total"
        :nextPage="this.nextPage"
        :idDivUtilizadaPaginacao="'feriados'"
        @search="aoPesquisar"
        :customHighlighters="this.customHighlighters"
        :modalDelecaoVisivel="this.verificarferiado"
        :desativarAtalhos="this.desativarAtalhosDataTable"
      >
        <template v-slot:new-item>
          <button data-group="button-novo-feriado" class="button is-primary" :title="shortkeys.MSG_KEY_NOVO" @click="cadastrar">Novo</button>
          <br />
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Ano</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control">
                  <input
                    id="ano"
                    class="input"
                    data-group="input-ano"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 3);"
                    type="number"
                    maxlength="4"
                    v-model="this.ano"
                    v-on:blur="this.buscar(this.ano, this.termoPesquisado)"
                    v-on:update="this.buscar(this.ano, this.termoPesquisado)"
                  />
                </p>
              </div>
            </div>
          </div>
        </template>
      </DataTableBasico>
    </div>
  </div>
  <tela-Edicao-Cadastro
    v-if="this.cadastrandoOuEditando"
    :item="this.item"
    :novoCadastro="this.novoCadastro"
    @save="this.atualizar"
    @cancel="this.cadastrandoOuEditando = false"
  />

  <modal-confirmacao
    v-if="this.showConfirmacao"
    :textoConfirmacao="this.mensagemConfirmacao"
    @on-nao="this.abreFechaConfirmacao()"
    @on-sim="this.replicarFeriadosFixos()"
  >
    <template v-slot:new-button>
      <button class="button is-primary" data-group="btn-cadastrar-todos" @click="this.replicarTodosOsFeriados()">
        Todos
      </button>
    </template>
    <template v-slot:new-textoConfirmacao>
      Tabela não encontrada. Deseja trazer os feriados Fixos do período
      anterior?<br />
      - Caso escolha a opção <b>Sim</b>, serão transportados os feriados
      Fixos.<br />
      - Caso escolha a opção <b>Não</b>, os feriados não serão transportados.<br />
      - Caso escolha a opção <b>Todos</b>, os feriados Fixos e Variáveis serão
      transportados.
    </template>
  </modal-confirmacao>
  <ModalMessage ref="focoModalExclusao" ok-caption="Excluir feriado" cancel-type="primary" :message="mensagem" :active="mostrarModal" @close="aoFechar" />

</template>

<script>
import Feriado from './model/feriado.js'
import { service } from '../../service.js'
import cadastroEdicao from './FeriadoEdicao.vue'
import { toast } from 'bulma-toast'
import modalConfirmacao from '../../components/ModalConfirmacao/index.vue'
import PageTitle from '../../components/PageTitle/index.vue'
import { mixinFocoPrimeiroElementoDataTable } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-foco-primeiro-elemento-data-table'
import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'
import ModalMessage from '@alterdata/component-vue/ModalMessage/index.vue'
import { mixinPesquisa } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-pesquisa'
import { shortkeys } from '@packonline/packonline-reutilizaveis-frontend/Utils/shortkeys'
import DataTableBasico from '@packonline/packonline-reutilizaveis-frontend/components/DataTableBasico.vue'

export default {
  components: {
    'tela-Edicao-Cadastro': cadastroEdicao,
    'modal-confirmacao': modalConfirmacao,
    'page-title': PageTitle,
    ModalMessage,
    DataTableBasico
  },
  mixins: [mixinFocoPrimeiroElementoDataTable, mixinPesquisa],
  inject: ['setActiveSection', 'addShortcut'],
  data () {
    return {
      dataSource: {
        columns: [
          {
            name: 'data',
            label: 'Data',
            format: this._formatarDataNoGrid,
            style: 'width:10%;text-align: center;',
            styleItems: 'text-align:center'
          },
          {
            name: 'descricao',
            label: 'Descrição',
            style: 'width:60%;'
          },
          {
            name: 'tpAmbito',
            label: 'Âmbito',
            format: this._formatarAmbitoNoGrid
          }
        ],
        selected: null,
        save: (item) => console.log('[save] ' + item),
        cursor: null,
        filter: () => this._carregarListagem(),
        select: (item) => this.editar(item),
        delete: (item) => this.prepararExclusao(item),
        loadMore: null
      },
      feriados: [],
      item: new Feriado(),
      page: [],
      status: '',
      hideButtonMore: false,
      showTitle: false,
      cadastrandoOuEditando: false,
      ano: new Date().getFullYear(),
      showConfirmacao: false,
      pagina: 0,
      termoPesquisado: '',
      oldParamAno: null,
      datatableKey: 0,
      mostrarModal: false,
      modalDelecao: true,
      preDelete: undefined,
      feriado: null,
      shortkeys: shortkeys,
      linkAjuda: 'https://ajuda.alterdata.com.br/display/POD/Cadastrar+Feriados',
      desativarAtalhosDataTable: false
    }
  },
  watch: {
    mostrarModal (val) {
      this.desativarAtalhosDataTable = val
    },
    cadastrandoOuEditando (val) {
      this.desativarAtalhosDataTable = val
    },
    showConfirmacao (val) {
      this.desativarAtalhosDataTable = val
    },
    '$route' (val) {
      val.fullPath === '/cadastros/feriados' ? this._definirAtalhosListagem() : this.setActiveSection('')
    }
  },
  computed: {
    mensagem () {
      if (this.preDelete) {
        return `Deseja excluir o feriado "${this.preDelete.descricao}"?`
      } else {
        return 'Deseja excluir o feriado ?'
      }
    }
  },
  methods: {
    async verificarferiado (paramFeriado) {
      const feriadoItens = await service.get(`/api/v1/feriados/${paramFeriado.id}`)
      this.feriado = feriadoItens.total > 0
      return false
    },
    customHighlighters (value, busca, item) {
      const stringfied = '' + value

      busca = String(busca).trim()
      if (
        !utils
          .removeAcentos(stringfied)
          .toLowerCase()
          .includes(utils.removeAcentos(busca).toLowerCase())
      ) {
        return value
      }

      const init = utils
        .removeAcentos(stringfied)
        .toLowerCase()
        .indexOf(utils.removeAcentos(busca).toLowerCase())
      const end = init + busca.length
      const extractedValue = stringfied.substring(init, end)

      return stringfied.replace(
        extractedValue,
        `<span class="has-background-warning">${extractedValue}</span>`
      )
    },
    prepararExclusao (item) {
      this._definirAtalhosExclusao()
      if (this.feriado) {
        this.excluir(item)
        this.setActiveSection('listagemFeriados')
      } else {
        this.mostrarModal = true
        this.preDelete = item
        this.$nextTick(() => {
          this.$refs.focoModalExclusao.buttonFocus({ type: 'cancel' })
        })
      }
    },
    async aoFechar (event) {
      if (event === 'ok') {
        await this.excluir(this.preDelete)
      } else {
        this.mostrarModal = false
      }
      this.setActiveSection('listagemFeriados')
    },
    async _carregarListagem (pagina = 0) {
      if (this.termoPesquisado) {
        await this.buscar(this.ano, this.termoPesquisado)
      } else {
        this.status = 'is-loading'
        try {
          this.empresas = []
          this.page = await service.get(`/api/v1/feriados?page=${pagina}`)
          this.empresas = this.page.content
          this.status = ''
          this._ordenarGridPorCodigo()
          this.pagina = pagina
          if (this.page.total === 0) {
            this.status = ''
            this.focarNaPesquisa()
          } else {
            this.focarNoPrimeiroElementoDataTable()
          }
        } catch {
          this.status = 'is-danger'
        }
      }
    },

    limparPesquisa () {
      this.termoPesquisado = ''
      this.$refs.datatable.$refs.search.value = ''
      this.datatableKey++
    },
    async aoPesquisar (event) {
      try {
        this.termoPesquisado = event.trim()
      } catch (e) {
        this.termoPesquisado = ''
      }
      this.buscar(this.ano, this.termoPesquisado)
    },

    _ordenarGridPorCodigo () {
      this.feriados.sort(function (a, b) {
        return a.data < b.data ? -1 : a.data > b.data ? 1 : 0
      })
    },

    async buscar (paramAno, search, pagina = 0) {
      this.status = ''
      paramAno = paramAno === '' ? 'SEM-ANO' : paramAno
      search = utils.campoVazio(search) ? 'SEM-PARAM' : search

      if (paramAno === 'SEM-ANO' && search === 'SEM-PARAM') {
        this.page = await service.get('/api/v1/feriados')
      } else {
        this.page = await service.get(
            `/api/v1/feriados/search?q=${[
              encodeURIComponent(paramAno),
              encodeURIComponent(search)
            ]}&page=${pagina}&sort=data`
        )
      }

      try {
        this.feriados = []
        this.pagina = pagina
        this.feriados = this.page.content
        this.status = ''
        if (this.page.total === 0) {
          if (search === 'SEM-PARAM') {
            this.showConfirmacao = true
            this.status = ''
            this._definirAtalhosReplicarFeriados()
          } else {
            this.status = ''
            this.focarNaPesquisa()
            this._definirAtalhosListagem()
          }
        } else {
          this.focarNoPrimeiroElementoDataTable()
        }
      } catch {
        this.status = 'is-danger'
      }
    },

    async carregar (ano) {
      this.status = 'is-loading'
      try {
        if (!ano) {
          this.page = await service.get('/api/v1/feriados')
        } else {
          this.page = await service.get(`/api/v1/feriados?ano=${ano}`)
        }

        if (this.page.total === 0) {
          this.feriados = []
          this.status = ''
          if (await this.existeFeriadoAnoNoAnoAnterior(this.ano)) {
            this.showConfirmacao = true
            this._definirAtalhosReplicarFeriados()
          }
        } else {
          if (this.page.total < this.page.pageable.size) {
            this.hideButtonMore = true
          } else {
            this.hideButtonMore = false
          }
          this.feriados = this.page.content
          for (var i = 0; i < this.feriados.length; i++) {
            this.feriados[i].data = this.feriados[i].data.substring(0, 10)
          }
          this.status = ''
          this.focarNoPrimeiroElementoDataTable()
          this._definirAtalhosListagem()
        }
      } catch {
        this.status = 'is-danger'
      }
    },

    async nextPage (pagina) {
      this.status = 'is-loading'
      try {
        this.feriados = []
        if (!this.ano) {
          this.page = await service.get(`/api/v1/feriados?page=${pagina}`)
        } else {
          this.page = await service.get(
            `/api/v1/feriados?ano=${this.ano}&page=${pagina}`
          )
        }

        if (this.page.total > 0) {
          this.feriados = this.page.content
          this.status = ''
          this.pagina = pagina
          this.focarNoPrimeiroElementoDataTable()
        } else {
          this.focarNaPesquisa()
        }
      } catch {
        this.status = 'is-danger'
      }
    },

    atualizar (feriado) {
      this.nextPage(this.pagina)
      this.abrirFecharEdicao()
    },

    _formatarDataNoGrid (data) {
      data = new Date(data)
      data = data.setHours(data.getHours() + 3)
      return new Intl.DateTimeFormat('pt-br').format(new Date(data))
    },

    _formatarAmbitoNoGrid (ambito) {
      switch (ambito) {
        case 'N':
          return 'Nacional'
        case 'E':
          return 'Estadual'
        case 'M':
          return 'Municipal'
      }
    },

    abrirFecharEdicao () {
      this.cadastrandoOuEditando = !this.cadastrandoOuEditando
    },

    cadastrar () {
      this.item = new Feriado()
      this.item.codigo = this.id
      this.novoCadastro = true
      this.abrirFecharEdicao()
    },

    editar (feriado) {
      Object.assign(this.item, feriado)
      this.novoCadastro = false
      this.abrirFecharEdicao()
    },

    async excluir (feriado) {
      const resposta = await service.delete('/api/v1/feriados', feriado)
      if (resposta.ok) {
        toast({
          message: 'Excluímos!',
          type: 'is-success',
          dismissible: true,
          pauseOnHover: true
        })
        const itemIndex = this.feriados.findIndex(
          (item) => item.id === feriado.id
        )
        this.feriados.splice(itemIndex, 1)
        const qtd = this.feriados.length
        if (qtd === 0) {
          this.carregar(this.ano)
          this.focarNoPrimeiroElementoDataTable()
        }
        this._ordenarGridPorCodigo()
        this.focarNoPrimeiroElementoDataTable()
        this.mostrarModal = false
      }
    },
    abreFechaConfirmacao () {
      this.showConfirmacao = !this.showConfirmacao
      if (!this.showConfirmacao) {
        this._definirAtalhosListagem()
      }
    },

    replicarFeriadosFixos () {
      var obj = {}
      obj.replicarApenasFeriadosFixos = true
      this.replicarFeriados(obj)
    },

    replicarTodosOsFeriados () {
      var obj = {}
      obj.replicarApenasFeriadosFixos = false
      this.replicarFeriados(obj)
    },

    replicarFeriados (obj) {
      obj.anoOrigem = this.ano - 1
      service.save('/api/v1/replicar-feriados', obj).then(this.responseToSave)
    },

    async responseToSave (response) {
      if (response.ok) {
        this.abreFechaConfirmacao()
        this.carregar(this.ano)
      }
    },

    async existeFeriadoAnoNoAnoAnterior (ano) {
      const list = await service.get(`/api/v1/feriados?ano=${ano - 1}`)

      if (list.total !== 0) {
        return true
      }

      return false
    },

    _definirAtalhosListagem () {
      const section = 'listagemFeriados'
      this.setActiveSection(section)
      this.addShortcut(section, shortkeys.KEY_NOVO, this.cadastrar)
      this.addShortcut(section, shortkeys.KEY_AJUDA, this._direcionaParaAjuda)
      this.addShortcut(section, shortkeys.KEY_AJUDA_ALTERNATIVA, this._direcionaParaAjuda)
    },

    _definirAtalhosExclusao () {
      const section = 'modalExclusao'
      this.setActiveSection(section)
      this.addShortcut(section, shortkeys.KEY_ESC, this.aoFechar)
      this.addShortcut(section, shortkeys.KEY_AJUDA, this._direcionaParaAjuda)
      this.addShortcut(section, shortkeys.KEY_AJUDA_ALTERNATIVA, this._direcionaParaAjuda)
    },

    _definirAtalhosReplicarFeriados () {
      const section = 'modalReplicarFeriados'
      this.setActiveSection(section)
      this.addShortcut(section, shortkeys.KEY_ESC, this.abreFechaConfirmacao)
      this.addShortcut(section, shortkeys.KEY_AJUDA, this._direcionaParaAjuda)
      this.addShortcut(section, shortkeys.KEY_AJUDA_ALTERNATIVA, this._direcionaParaAjuda)
    },

    _direcionaParaAjuda () {
      window.open(this.linkAjuda, '_blank').focus()
    }
  },

  mounted () {
    this.$router.currentRoute.value.fullPath === '/feriados'
      ? (this.showTitle = true)
      : (this.showTitle = false)
    this.buscar(this.ano, this.termoPesquisado)
    this._definirAtalhosListagem()
  },

  unmounted () {
    this.setActiveSection('')
  }
}
</script>
<style scoped>
#ano {
  width: 50%;
}
</style>
