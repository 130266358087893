<template>
  <div>
    <h1 class="title is-4 mb-3"> <slot /> </h1>
  </div>
</template>

<script>
export default {
}
</script>
