<template>
    <Modal title="Feriado" :active="true" id="cadastroEdicaoFeriado" @close="this._sairSemSalvar()">
          <div id="data" class="field column is-9">
            <input-data
              label="Data"
              v-model="feriado.data"
              :modelValue="feriado.data"
              @update:erro="erros.data = $event"
              @update:valid="validadorData = $event"
              :status="this.getStatus(erros.data)"
              :message="erros.data"
              required
            />
          </div>

          <div class="field column is-9">
            <input-texto
              id="descricao"
              maxlength="120"
              label="Descrição"
              v-model="this.feriado.descricao"
              :status="this.getStatus(erros.descricao)"
              :message="erros.descricao"
              @update:erro="erros.descricao = $event.message"
              required
            />
          </div>

          <div class="column is-12" style="padding-bottom: 0rem; margin: 0">
            <label required class="label" for="tipo"><span style="color: #C22743">*</span> Tipo</label>
            <label class="radio" style="padding-right: 2.5rem">
              <input
                type="radio"
                name="tipo"
                value="F"
                v-model="this.feriado.tpFeriado"
              />
              Fixo
            </label>
            <label class="radio" style="padding-right: 2.5rem">
              <input
                type="radio"
                name="tipo"
                value="V"
                v-model="this.feriado.tpFeriado"
              />
              Variável
            </label>
            <p class="help is-danger">{{ erros.tpFeriado }}</p>
          </div>

          <div class="column is-12" style="padding-bottom: 0rem; margin: 0">
           <label class="label" for="ambito">  <span style="color: #C22743">*</span> Âmbito</label>
            <label class="radio" style="padding-right: 2.5rem">
              <input required
                type="radio"
                name="ambito"
                value="N"
                v-model="this.feriado.tpAmbito"
              />
              Nacional
            </label>
            <label class="radio" style="padding-right: 2.5rem">
              <input required
                type="radio"
                name="ambito"
                value="E"
                v-model="this.feriado.tpAmbito"
              />
              Estadual
            </label>
            <label class="radio" style="padding-right: 2.5rem">
              <input required
                type="radio"
                name="ambito"
                value="M"
                v-model="this.feriado.tpAmbito"
              />
              Municipal
            </label>
            <p class="help is-danger">{{ erros.tpAmbito }}</p>
            <municipio-uf
              v-if="this.exibeUf"
              :pExibeMunicipio="this.exibeMunicipio"
              :pUf="this.feriado.uf"
              :pCdMunicipio="this.feriado.cdMunicipio"
              :pErros="this.erros"
              :erro="'cdMunicipio'"
              @carregarObjSelecionado="this.carregarObjSelecionado"
            />
          </div>
          <template v-slot:footer>
            <ButtonAjuda :linkAjuda="this.linkAjuda" :title="shortkeys.MSG_KEY_AJUDA" style="margin-right: auto" />
            <Button class="button is-primary" @click="this._salvarFeriado" :title="shortkeys.MSG_KEY_GRAVAR" caption="Gravar" style="margin-right: 1rem"/>
            <Button class="button" @click="this.on_cancelar" :title="shortkeys.MSG_KEY_ESC" caption="Cancelar"/>
          </template>
      </Modal>
</template>

<script>
import Feriado from './model/feriado.js'
import { service } from '../../service.js'
import municipioUf from '../../components/PesquisaMunicipioUF/index.vue'
import InputText from '@alterdata/component-vue/InputText'
import InputDate from '@alterdata/component-vue/InputDate/index.vue'
import { mixin } from '../../util/mixin.js'
import Button from '@alterdata/component-vue/Button/index.vue'
import Modal from '@alterdata/component-vue/Modal/index.vue'
import { shortkeys } from '@packonline/packonline-reutilizaveis-frontend/Utils/shortkeys'
import ButtonAjuda from '@packonline/packonline-reutilizaveis-frontend/components/ButtonAjuda.vue'
import { mixinModalSaida } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-modal-saida-exclusao.js'
import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'

const ERROS = {
  data: '',
  descricao: '',
  tpFeriado: '',
  tpAmbito: '',
  uf: '',
  cdMunicipio: ''
}

export default {
  components: {
    'municipio-uf': municipioUf,
    'input-data': InputDate,
    'input-texto': InputText,
    Button,
    Modal,
    ButtonAjuda
  },
  props: {
    item: Feriado,
    novoCadastro: Boolean
  },
  mixins: [mixin, mixinModalSaida],
  inject: ['setActiveSection', 'addShortcut'],
  data () {
    return {
      feriado: this.item,
      feriadoOriginal: new Feriado(),
      novoCad: this.novoCadastro,
      erros: ERROS,
      exibeMunicipio: false,
      exibeUf: false,
      municipio: [],
      shortkeys: shortkeys,
      linkAjuda: 'https://ajuda.alterdata.com.br/display/POD/Cadastrar+Feriados'
    }
  },
  computed: {
    errosData () {
      return this.erros.data
    },
    verificandoDadosAlteradosRoot () {
      return this.$root.verificandoDadosAlterados
    },
    modalSaida () {
      return this.$root.mostrarModalDadosAlterados
    }
  },

  methods: {
    async _salvarFeriado () {
      const possuiErro = Feriado.validar(this.feriado, this.validadorData, this.erros)
      if (!possuiErro) {
        this.feriado.ano = this.feriado.data.substring(0, 4)
        this.feriado.data = this._formataData(this.feriado.data)
        this.feriado.tpAtividade = 'A'
        switch (this.feriado.tpAmbito) {
          case 'M':
            this.feriado.uf = null
            this.feriado.cdMunicipio = this.municipio.cdMunicipio
            break
          case 'E':
            this.feriado.uf = this.municipio.uf
            this.feriado.cdMunicipio = null
            break
          case 'N':
            this.feriado.uf = null
            this.feriado.cdMunicipio = null
            break
        }
        await service.save('/api/v1/feriados', this.feriado).then(this.responseToSave)

        if (sessionStorage.getItem('rotaClicadaNoMenuLateral') !== null) {
          const rotaClicadaNoMenuLateral = sessionStorage.getItem('rotaClicadaNoMenuLateral')
          sessionStorage.removeItem('rotaClicadaNoMenuLateral')
          this.$router.push(`${rotaClicadaNoMenuLateral}`)
        } else {
          this.$router.push('/cadastros/feriados')
        }
      }
      this.dadosInalterados()
    },

    async responseToSave (response) {
      const json = await response.json()

      if (response.ok) {
        this.$emit('save', json)
      } else if (response.status === 422) {
        this.erros = json
      } else if (response.status === 409) {
        Object.assign(this.erros, { codigo: json.message })
      }
    },

    _formataData (data) {
      data = new Date(data)
      data = data.toISOString()
      return data
    },
    carregarObjSelecionado (municipio) {
      this.municipio = municipio
      this.feriado.uf = this.municipio.uf
      this.feriado.cdMunicipio = this.municipio.cdMunicipio
    },
    exibirMunicipioUf () {
      if (this.feriado.tpAmbito === 'M') {
        this.exibeUf = true
        this.exibeMunicipio = true
      } else if (this.feriado.tpAmbito === 'E') {
        this.exibeUf = true
        this.exibeMunicipio = false
      } else {
        this.exibeUf = false
        this.exibeMunicipio = false
      }
    },
    async carregarUf () {
      if (this.feriado.tpAmbito === 'M') {
        var municipio = await service.get(
          `/api/v1/municipio/${this.feriado.cdMunicipio}`
        )
        this.feriado.uf = municipio.uf
        this.feriadoOriginal.uf = municipio.uf
      }
    },
    on_cancelar () {
      if (!this._verificarModificaoNoItem()) {
        this._sairSemSalvar()
      }
    },

    _limparErros () {
      this.erros.data = ''
      this.erros.descricao = ''
      this.erros.tpFeriado = ''
      this.erros.tpAmbito = ''
      this.erros.uf = ''
      this.erros.cdMunicipio = ''
    },

    _sairSemSalvar () {
      this.dadosInalterados()
      this._limparErros()
      this.$emit('cancel')
      this.setActiveSection('listagemFeriados')

      if (sessionStorage.getItem('rotaClicadaNoMenuLateral') !== null) {
        const rotaClicadaNoMenuLateral = sessionStorage.getItem('rotaClicadaNoMenuLateral')
        sessionStorage.removeItem('rotaClicadaNoMenuLateral')
        this.$router.push(`${rotaClicadaNoMenuLateral}`)
      } else {
        this.$router.push('/cadastros/feriados')
      }
    },

    _verificarModificaoNoItem () {
      const item = JSON.parse(JSON.stringify(utils.cloneDeep(this.feriado)))
      const itemOriginal = JSON.parse(JSON.stringify(utils.cloneDeep(this.feriadoOriginal)))
      const existeModificacao = !utils.isEqual(item, itemOriginal)
      if (existeModificacao) {
        this.dadosAlterados()
        return true
      } else {
        this.dadosInalterados()
        return false
      }
    },

    _definirAtalhosCadastroFeriados () {
      const section = 'cadastroFeriados'
      this.setActiveSection(section)
      this.addShortcut(section, shortkeys.KEY_GRAVAR, this._salvarFeriado)
      this.addShortcut(section, shortkeys.KEY_ESC, this.on_cancelar)
      this.addShortcut(section, shortkeys.KEY_AJUDA, this._direcionaParaAjuda)
      this.addShortcut(section, shortkeys.KEY_AJUDA_ALTERNATIVA, this._direcionaParaAjuda)
    },

    _direcionaParaAjuda () {
      window.open(this.linkAjuda, '_blank').focus()
    }
  },
  watch: {
    feriado: {
      handler: function (val, old) {
        this.exibirMunicipioUf(this.feriado)
        if (val.descricao) {
          this.erros.descricao = ''
        }
        if (val.tpFeriado) {
          this.erros.tpFeriado = ''
        }
        if (val.tpAmbito) {
          this.erros.tpAmbito = ''
        }
        if (val.uf) {
          this.erros.uf = ''
        }
        if (val.cdMunicipio) {
          this.erros.cdMunicipio = ''
        }
      },
      deep: true
    },
    modalSaida (val) {
      if (!val) {
        this.setActiveSection('cadastroFeriados')
      }
    },
    verificandoDadosAlteradosRoot (val) {
      if (val && this.feriadoOriginal) {
        this._verificarModificaoNoItem()
      }
    }
  },

  mounted () {
    this.carregarUf()
    this.exibirMunicipioUf()
    this.feriadoOriginal = utils.cloneDeep(this.item)
    this.focarNoPrimeiroInput('data')
    this._definirAtalhosCadastroFeriados()
    this._limparErros()
    this.dadosInalterados()
    const { parametrosModalSalvarMudancas } = this.$root
    parametrosModalSalvarMudancas.eventoSalvar = this._salvarFeriado
    parametrosModalSalvarMudancas.eventoSair = this._sairSemSalvar
  },

  unmounted () {
    this.setActiveSection('listagemFeriados')
  }
}
</script>
<style scoped>
#data {
  width: 50%;
}

</style>
