<template>
  <div id="modalConfirmacao">
    <div class= "modal animated fadeIn is-active">
      <div class="modal-background"></div>
        <div class="modal-content" id="modalConfirmacao">
          <header class="modal-card-head">
            <div class="icon-text">
              <span class="icon"><span class="fas fa-question-circle fa-lg"></span></span>
              <span>Confirmação</span>
            </div>
          </header>
          <section class="modal-card-body">
            <div class="field" id="textoConfirmacao">
              <label class="text" :textContent="this.textoConfirmacao">
              </label>
              <slot name="new-textoConfirmacao"></slot>
            </div>
          </section>
            <footer class="modal-card-foot is-justify-content-flex-end">
              <div class="buttons">
                <button class="button is-primary" data-group="btn-cadastrar-fixos" @click="this.$emit('on-sim')">Sim</button>
                <button class="button" data-group="btn-nao-cadastrar" @click="this.$emit('on-nao')">Não</button>
                <slot name="new-button"></slot>
              </div>
            </footer>
        </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'confirmacao',
  props: {
    textoConfirmacao: String
  }
}
</script>
<style scoped>
  #textoAviso{
    padding-bottom: 0rem;
    margin: 0
  }
  #modalConfirmacao{
    width: 700px
  }
</style>
