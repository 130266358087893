<template>
  <div class="container" style="text-align: left">
    <div class="columns is-multiline">
      <div
        class="field column is-4"
        style="padding-bottom: 0rem; margin: 0; padding-right: 0"
      >
        <div class="field has-addons">
          <combo-box
            id="uf"
            v-model="this.uf"
            label="UF"
            :options="this.UFs"
            :message="this.erros.uf"
            :status="this.getStatus(this.erros.uf)"
            :key="comboBoxUFKey"
            list-by="nome"
            @blur="
              !this.municipio.uf && this.pMod === 'cadastro-empresa'
                ? (this.erros.uf = 'Informe o UF')
                : ''
            "
            :required="this.pMod === 'cadastro-empresa'"
            :disabled="this.disabled"
          />
        </div>
      </div>
      <div
        v-if="this.exibeMunicipio"
        class="field column is-8"
        style="
          padding-bottom: 0rem;
          margin: 0;
          padding-right: 5px;
          padding-left: 0;
        "
      >
        <div class="field has-addons">
          <input-texto
            id="municipio"
            v-model="this.municipio.nmMunicipio"
            label="Município"
            :status="this.getStatus(this.pErros[this.erro])"
            :message="this.pErros[this.erro]"
            @update:erro="this.erros[this.erro] = $event.message"
            @blur="
              this.verificaMunicipio;
              this.carregarCodigoMunicipioIbge();
            "
            :disabled="this.municipio.uf === 'EX' || this.disabled"
            :required="this.requerido"
          >
            <template v-slot:addon>
              <a
                v-if="this.municipio.uf === 'EX' || this.disabled"
                class="button"
                disabled
              >
                <span class="icon"><span class="fas fa-search"></span></span>
              </a>
              <a
                v-else
                class="button is-outlined"
                :class="{
                  'is-danger':
                    this.getStatus(this.pErros[this.erro]) === 'error',
                  'is-info': this.getStatus(this.pErros[this.erro]) === 'info'
                }"
                @click="this.abrirPesquisaMunicipio()"
              >
                <span class="icon"><span class="fas fa-search"></span></span>
              </a>
            </template>
          </input-texto>
        </div>
      </div>
    </div>
  </div>
  <pesquisa
    v-if="this.modalVisivel"
    :titulo="'Pesquisa de municípios'"
    :propDataSource="this.dataSource"
    :propItens="this.municipios"
    :pHideButtonMore="this.hideButtonMore"
    :pstatus="this.status"
    @carregarItemSelecionado="this.carregarItemSelecionado"
    @fechar="this.fecharPesquisaMunicipio"
    :pQuantidadeDeItems="this.page.total"
    :nextPage="this.carregar"
    pSortedBy="nmMunicipio"
    customSearch
    @search="aoPesquisar"
  />
</template>
<script>
import pesquisa from '../Pesquisa/index.vue'
import { service } from '../../service.js'
import jsonUfs from './ufs.json'
import InputText from '@alterdata/component-vue/InputText/index.vue'
import ComboBox from '@alterdata/component-vue/ComboBox/index.vue'
import { mixin } from '../../util/mixin'

export default {
  mixins: [mixin],
  components: {
    pesquisa,
    'input-texto': InputText,
    'combo-box': ComboBox
  },
  props: {
    pMod: String,
    pExibeMunicipio: Boolean,
    pCdMunicipio: String,
    pNmMunicipio: String,
    pIdMunicipio: String,
    pUf: {
      type: String,
      required: true
    },
    pErros: Object,
    erro: String,
    requerido: {
      type: Boolean,
      default: false
    },
    disabled: Boolean
  },
  data () {
    return {
      municipio: {
        uf: this.pUf,
        cdMunicipio: this.pCdMunicipio,
        nmMunicipio: this.pNmMunicipio,
        idMunicipio: this.pIdMunicipio
      },
      exibeMunicipio: this.pExibeMunicipio,
      modalVisivel: false,
      erros: this.pErros,
      dataSource: {
        columns: [
          {
            name: 'uf',
            label: 'UF',
            style: 'width:7%'
          },
          {
            name: 'nmMunicipio',
            label: 'Município',
            style: 'width:80%'
          },
          {
            name: 'codMunicipioIbge',
            label: 'Código IBGE',
            style: 'width:13%'
          }
        ],
        selected: null,
        save: (item) => console.log('[save] ' + item),
        cursor: null,
        filter: () => this.carregar(this.municipio.uf),
        loadMore: null
      },
      municipios: [],
      hideButtonMore: false,
      status: '',
      page: [],
      UFs: jsonUfs.data,
      timer: undefined,
      termoPesquisado: '',
      comboBoxUFKey: 0
    }
  },

  methods: {
    async carregar (pagina = 0, uf = '') {
      this.status = 'is-loading'
      if (this.termoPesquisado) {
        await this.busca(this.termoPesquisado, pagina)
      } else {
        try {
          if (!this.municipio.uf) {
            this.page = await service.get(`/api/v1/municipio?page=${pagina}`)
          } else {
            this.page = await service.get(`/api/v1/municipio/search?q=${[encodeURIComponent(this.municipio.uf)]}&page=${pagina}&sort=nmMunicipio`)
          }
          if (this.page.total === 0) {
            this.status = 'is-empty'
            this.municipios = []
          } else {
            if (this.page.total < this.page.pageable.size) {
              this.hideButtonMore = true
            } else {
              this.hideButtonMore = false
            }
            this.municipios = this.page.content
            this.status = ''
          }
        } catch {
          this.status = 'is-danger'
        }
      }
    },

    async aoPesquisar (event) {
      this.termoPesquisado = event.trim()
      await this.carregar(0, this.municipio.uf)
    },

    async busca (search, pagina) {
      this.status = 'is-loading'
      try {
        this.municipios = []
        let uf
        try {
          uf = this.municipio.uf ? this.municipio.uf : ''
        } catch {
          uf = ''
        }
        this.page = await service.get(`/api/v1/municipio/search?q=${[encodeURIComponent(search), encodeURIComponent(uf)]}&page=${pagina}&sort=nmMunicipio`)
        this.municipios = this.page.content
        this.status = ''
      } catch {
        this.status = 'is-danger'
      }
    },

    abrirPesquisaMunicipio () {
      this.carregar(0, this.municipio.uf)
      this.modalVisivel = true
    },

    fecharPesquisaMunicipio () {
      this.modalVisivel = false
      this.termoPesquisado = ''
      const input = document.querySelector('input#municipio')
      if (input) {
        input.focus()
      }
    },
    async carregarNomeMunicipio () {
      if (this.exibeMunicipio && this.municipio.cdMunicipio) {
        var response = await service.get(
          `/api/v1/municipio/${this.municipio.cdMunicipio}`
        )
        this.municipio.nmMunicipio = response.nmMunicipio
        this.municipio.idMunicipio = response.idMunicipio
        this.municipio.cdMunicipio = response.codMunicipioIbge
        this.municipio.uf = response.uf
      }
    },

    verificaMunicipio (e) {
      if (e.target.value === '' && this.pMod === 'cadastro-empresa') {
        this.erros[this.erro] = 'Informe o município'
      }
    },
    async carregarCodigoMunicipioIbge () {
      let response = null
      try {
        response = await service.get(
          `/api/v1/municipio/nome-municipio?nmMunicipio=${this.municipio.nmMunicipio}`
        )
        if (response.status === 404) {
          this.erros[this.erro] = 'Município não encontrado'
        }
      } catch (e) {
        this.erros[this.erro] = 'Município não encontrado'
      }
      if (response) {
        const municipioBuscado = await this._buscarMunicipioDeAcordoComAUF(response)
        if (municipioBuscado) {
          this.municipio.cdMunicipio = municipioBuscado.codMunicipioIbge
          this.municipio.idMunicipio = municipioBuscado.idMunicipio
          this.municipio.uf = municipioBuscado.uf
          this.erros[this.erro] = ''
        }
      } else {
        this.erros[this.erro] = 'Município não encontrado'
      }
    },
    async _buscarMunicipioDeAcordoComAUF (municipios) {
      let municipioEncontrado = null
      if (!municipios.empty) {
        if ((municipios.length > 1) && this.uf) {
          municipioEncontrado = await municipios.find(municipio => municipio.uf === this.uf.nome)
          if (!municipioEncontrado) {
            municipioEncontrado = municipios[0]
          }
        } else {
          municipioEncontrado = municipios[0]
        }
      } else {
        this.erros[this.erro] = 'Município não encontrado'
      }
      return municipioEncontrado || null
    },
    carregarItemSelecionado (municipio) {
      this.municipio.uf = municipio.uf
      this.municipio.nmMunicipio = municipio.nmMunicipio
      this.municipio.cdMunicipio = municipio.codMunicipioIbge
      this.municipio.idMunicipio = municipio.idMunicipio
      this.fecharPesquisaMunicipio()
    },
    parouDeDigitar (e) {
      var charCode = e.which ? e.which : e.keyCode
      var digitando = false
      clearTimeout(this.timer)

      if (charCode <= 90 && charCode >= 48) {
        digitando = true
        this.timer = setTimeout(() => {
          this.carregarCodigoMunicipioIbge()
        }, 1000)
      }

      if (
        e.target.value !== null &&
        digitando === false &&
        e.target.value !== ''
      ) {
        this.timer = setTimeout(() => {
          this.carregarCodigoMunicipioIbge()
        }, 1000)
      }
    }
  },
  computed: {
    uf: {
      get () {
        const uf = []

        if (this.municipio.uf) {
          JSON.parse(JSON.stringify(this.UFs)).map((e) => {
            if (this.municipio.uf.indexOf(e.nome) !== -1) {
              uf.push(e)
            }
          })

          return uf.reduce((a, b) => (a.nome.length >= b.nome.length ? a : b))
        } else {
          return this.municipio.uf
        }
      },
      set (newValue) {
        this.municipio.uf = newValue.nome
      }
    }
  },
  watch: {
    pExibeMunicipio: {
      immediate: true,
      handler () {
        if (this.pExibeMunicipio) {
          this.exibeMunicipio = true
        } else {
          this.exibeMunicipio = false
        }
      },
      deep: true
    },
    pUf: {
      immediate: true,
      handler () {
        this.municipio.uf = this.pUf
        if (this.municipio.uf === 'EX') {
          this.$emit('limparCampos')
        }
        this.municipio.nmMunicipio = null
        this.municipio.cdMunicipio = null
        this.municipio.idMunicipio = null
      },
      deep: true
    },
    'municipio.uf' (val) {
      if (val !== undefined) {
        this.comboBoxUFKey++
      }
    },
    pCdMunicipio: {
      immediate: true,
      handler () {
        this.municipio.cdMunicipio = this.pCdMunicipio
        this.carregarNomeMunicipio()
      },
      deep: true
    },
    pNmMunicipio: {
      immediate: true,
      handler () {
        this.municipio.nmMunicipio = this.pNmMunicipio
      },
      deep: true
    },
    pIdMunicipio: {
      immediate: true,
      handler () {
        this.municipio.idMunicipio = this.pIdMunicipio
      },
      deep: true
    },
    municipio: {
      immediate: true,
      handler () {
        this.erros[this.erro] = ''
        this.$emit('carregarObjSelecionado', this.municipio)
      },
      deep: true
    },
    pErros: {
      immediate: true,
      handler (val) {
        this.erros = this.pErros
      },
      deep: true
    }
  },
  created () {
    if (this.pExibeMunicipio) {
      this.exibeMunicipio = true
    } else {
      this.exibeMunicipio = false
    }
    if (
      this.pMod === 'cadastro-socio' &&
      !this.UFs.some((e) => e.nome === 'EX')
    ) {
      this.UFs.push({ nome: 'EX' })
    }
  },
  async mounted () {
    if (this.municipio.nmMunicipio) {
      const response = await service.get(
        `/api/v1/municipio/nome-municipio?nmMunicipio=${this.municipio.nmMunicipio}`
      )
      if (response) {
        const municipioBuscado = await this._buscarMunicipioDeAcordoComAUF(response)
        if (municipioBuscado) {
          this.municipio.cdMunicipio = municipioBuscado.codMunicipioIbge
        }
      }
      this.comboBoxUFKey++
    }
  },
  renderTracked () {
    this.erros[this.erro] = ''
  }
}
</script>
